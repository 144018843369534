import * as admin_assets from "../../assets/Admin";
import AdminLayout from "../../component/AdminLayout/AdminLayout";
import AdminProducts from "../../component/AdminProducts";
import "./style.css";
import InputField from "../../component/InputField/InputField";
import { TextField } from "@mui/material";

const AdminProductsPage = () => {

    return (
        <>
            <AdminLayout>
                <div className="adminLayout d-flex justify-content-center">
                    <div className="content-dashboard admin-panel-content py-0 py-md-3 ps-0 pe-0 pe-md-4 py-md-5 w-100">

                        <div
                            className="content-header align-items-center d-flex justify-content-between"
                        >
                            <div>
                                <h2 className="ms-4 fs-4 mt-4 " >Admin Panel</h2>
                                <p className="ms-4 sub-heading mt-3">Products</p>
                            </div>

                            <div>
                                <button
                                    type="button"
                                    className=" btn-admin btn-admin-primary"
                                    data-bs-toggle="modal"
                                    data-bs-target="#add-section"
                                >
                                    Add new product
                                </button>
                            </div>
                        </div>

                        <div className="content-body px-2 pe-md-5 mt-3" >
                            <div className="row gutter gy-4">
                                <AdminProducts />
                                <AdminProducts />
                                <AdminProducts />
                                <AdminProducts />
                                <AdminProducts />
                                <AdminProducts />
                                <AdminProducts />
                                <AdminProducts />
                                <AdminProducts />
                                <AdminProducts />
                                <AdminProducts />
                                <AdminProducts />
                                <AdminProducts />


                            </div>
                        </div>

                    </div>
                </div>

            </AdminLayout>
            <div
                className="modal fade  "
                id="add-section"
                data-bs-backdrop="static"
                data-bs-keyboard="false"
                tabIndex="-1"
                aria-labelledby="add-section-label"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-dialog-centered"

                >
                    <div className="modal-content"
                        style={{ borderRadius: "10px" }}

                    >
                        <div className="modal-header text-center m-auto border-0" >
                            <img src={admin_assets.adminWhiteLogo} alt="" />
                            <button
                                style={{ marginLeft: "40px" }}
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            ></button>
                        </div>
                        <div className="modal-body" style={{ padding: "35px" }}>
                            <h4 style={{ color: '#343434', fontSize: "18px", lineHeight: "21px", fontWeight: 700 }}>Add Product</h4>
                            <p
                                style={{ color: '#343434', fontSize: "14px", lineHeight: "21px", fontWeight: 400 }}
                                className="sub-heading">Add Product Details</p>
                            <div className="custom-upload-file position-relative">
                                <input
                                    type="file"
                                    className="position-absolute inset-0 h-75 w-100 opacity-0"
                                />
                                <div className="file-placeholder text-center">
                                    <img src={admin_assets.fiileIcon} alt="" className="w-25 mt-4" />

                                    <p style={{ color: "#343434" }} className="mt-3">Upload/ Drag and Drop file here</p>
                                </div>
                            </div>
                            <div className="d-flex flex-column w-75 mt-3 ">
                                <label className="" name="productName" style={{ color: "#4C4D4F", fontFamily: "cabin", fontWeight: 400, fontSize: "14px", opacity: "50%" }}> Product name</label>
                                <InputField variant="outlined" id="outlined-basic" size="small" fullWidth styledInputProps={{ height: "2px", type: "text" }} />
                                <label className="" name="productName" style={{ color: "#4C4D4F", fontFamily: "cabin", fontWeight: 400, fontSize: "14px", opacity: "50%" }}> Product price</label>
                                <InputField variant="outlined" id="outlined-basic" size="small" fullWidth styledInputProps={{ height: "2px", type: "text" }} />
                                <label className="" name="productName" style={{ color: "#4C4D4F", fontFamily: "cabin", fontWeight: 400, fontSize: "14px", opacity: "50%" }}> Product Quantity</label>
                                <InputField variant="outlined" id="outlined-basic" size="small" fullWidth styledInputProps={{ height: "2px", type: "text" }} />
                                <label className="" name="productName" style={{ color: "#4C4D4F", fontFamily: "cabin", fontWeight: 400, fontSize: "14px", opacity: "50%" }}> Product Description</label>
                                <TextField
                                    id="outlined-multiline-static"
                                    multiline
                                    rows={2}
                                    variant="outlined"
                                />

                            </div>
                        </div>
                        <div className="modal-footer border-0">
                            <button
                                type="button"
                                className="btn-admin btn-admin-primary"
                                data-bs-dismiss="modal"
                            >
                                Upload
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>

    )

}


export default AdminProductsPage;