import React from "react";
import styled from "styled-components";
import Image1 from "../../assets/notfound/top-right.png";
import Image2 from "../../assets/notfound/top-left.png";
import Image3 from "../../assets/notfound/down-left.png";
import Image4 from "../../assets/notfound/down-right.png";
import LogoImage from "../../assets/notfound/SkinClubLogo_Logotype_White 1.png";

const Header = styled.header`
  width: 100%;
  // height: 60px;
`;

const Logo = styled.img`
  width: 280px;
  // height: 37px;
  display: flex;
  margin: 0 auto;
  padding-top: 9px;
`;

const Container = styled.div`
  width: 100%;
  height: 573px;
  text-align: center;
  background-color: #fff9f1;
  position: relative;
`;

const Heading = styled.h4`
  font-size: 85px;
  color: #4c4d4f;
  font-weight: 400;
  margin: 0;
  padding-top: 140px;
`;

const MainText = styled.div`
  font-size: 34px;
  font-weight: 400;
  color: #4c4d4f;
  margin-top: 20px;
`;

const SubText = styled.p`
  font-size: 14px;
  font-weight: 400;
  color: #4c4d4f80;
  // margin-top: -40px;
`;

const Button = styled.button`
  width: 280px;
  height: 48px;
  color: #fff;
  background-color: #000;
  border-radius: 24px;
  border: transparent;
  margin-top: 14px;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
`;

const Vector = styled.img`
  position: absolute;
  width: 33%;
  height: 364px;
  left: 67%;
`;

const Vector1 = styled.img`
  position: absolute;
  width: 27%;
  height: 364px;
  // top: 70px;
  top: 0px;
  left: 8px;
`;

const Vector2 = styled.img`
  position: absolute;
  width: 39%;
  height: 256px;
  top: 385px;
  top: 350px;
  left: 8px;
`;

const Vector3 = styled.img`
  position: absolute;
  width: 23%;
  height: 245px;
  top: 330px;
  left: 76%;
`;

const NotFound = () => {
  return (
    <>
      <Header>
        <Logo src={LogoImage} alt="" />
      </Header>
      <Container>
        <Vector src={Image1} alt="" />
        <Vector1 src={Image2} alt="" />
        <Vector2 src={Image3} alt="" />
        <Vector3 src={Image4} alt="" />
        <MainText>
          <Heading>404</Heading>
          <h5>Page Not Found</h5>
          <SubText>
            We’re sorry, the page you requested could not be found
          </SubText>
          <Button>Let’s Go Back To Home Page</Button>
        </MainText>
      </Container>
    </>
  );
};

export default NotFound;
