import React from "react";
import Forget from "../component/Forget/Forget";

import Header from "../component/Header/Header";
import Styled from "styled-components";
import AuthLayout from "../component/AuthLayout/AuthLayout";
import SignUpImage from "../assets/forgetpass.png";

const SignupWrapper = Styled.div`
  background: #FFF9F1;
  // height: 100vh;

  .auth {
    // background: red;
    // height:100%;
  padding:4.7rem;

  @media only screen and (max-width: 600px) {
    padding:4.7rem 2.4rem;
  }
  }
`;

const ForgetPassword = () => {
  return (
    <div>
      <SignupWrapper>
        <div>
          <Header />
          <AuthLayout className="auth" src={SignUpImage} isImage>
            <Forget />
          </AuthLayout>
        </div>
      </SignupWrapper>
    </div>
  );
};

export default ForgetPassword;
