import AdminLayout from "../../component/AdminLayout/AdminLayout";
import * as admin_assets from "../../assets/Admin";
import React from "react";
import AdminActiveFormContent from "../../component/AdminActiveFormContent";

const AdminActiveCareFroms = () => {
    return (
        <>
            <AdminLayout>
                <div className="adminLayout d-flex justify-content-center">
                    <div className="content-dashboard admin-panel-content py-0 py-md-3 ps-0 pe-0 pe-md-4 py-md-5 w-100">

                        <div
                            className="content-header align-items-center d-flex justify-content-between"
                        >
                            <div>
                                <h2 className="ms-4 fs-4 mt-4 " >Admin Panel</h2>
                                <p className="ms-4 sub-heading mt-3">Treatments</p>
                            </div>

                            <div>
                                <button
                                    type="button"
                                    className=" btn-admin btn-admin-primary"
                                    data-bs-toggle="modal"
                                    data-bs-target="#add-form"
                                >
                                    Upload Form
                                </button>
                            </div>
                        </div>

                        <div className="content-body px-2 pe-md-5 mt-3" >
                            <div className="row gutter">
                                <AdminActiveFormContent />
                                <AdminActiveFormContent />
                                <AdminActiveFormContent />
                                <AdminActiveFormContent />
                                <AdminActiveFormContent />
                                <AdminActiveFormContent />


                            </div>
                        </div>

                    </div>
                </div>

            </AdminLayout>
            <div
                className="modal fade  "
                id="add-form"
                data-bs-backdrop="static"
                data-bs-keyboard="false"
                tabIndex="-1"
                aria-labelledby="add-section-label"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-dialog-centered"

                >
                    <div className="modal-content"
                        style={{ borderRadius: "10px" }}

                    >
                        <div className="modal-header text-center m-auto border-0" >
                            <img src={admin_assets.adminWhiteLogo} alt="" />
                            <button
                                style={{ marginLeft: "40px" }}
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            ></button>
                        </div>
                        <div className="modal-body" style={{ padding: "35px" }}>
                            <h4 style={{ color: '#343434', fontSize: "18px", lineHeight: "21px", fontWeight: 700 }}>Upload Form</h4>
                            <p
                                style={{ color: '#343434', fontSize: "14px", lineHeight: "21px", fontWeight: 400 }}
                                className="sub-heading">Select a file from your device</p>
                            <div className="custom-upload-file position-relative">
                                <input
                                    type="file"
                                    className="position-absolute inset-0 h-75 w-100 opacity-0"
                                />
                                <div className="file-placeholder text-center">
                                    <img src={admin_assets.fiileIcon} alt="" className="w-25 mt-4" />

                                    <p style={{ color: "#343434" }} className="mt-3">Upload/ Drag and Drop file here</p>
                                </div>
                            </div>

                        </div>
                        <div className="modal-footer border-0">
                            <button
                                type="button"
                                className="btn-admin btn-admin-primary"
                                data-bs-dismiss="modal"
                            >
                                Upload
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default AdminActiveCareFroms;



