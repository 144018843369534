import PropTypes from "prop-types";
import TextField from "@mui/material/TextField";
import styled from "@emotion/styled";
import { Typography } from "@mui/material";
// import { colors } from "@config/colors";
import { colors } from "../../config/colors";

const StyledInput = styled("div")({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  width: "100%",
});
function InputField({
  variant,
  ref,
  children,
  InputProps,
  styledInputProps,
  ...props
}) {
  const inputVariant = () => (
    <StyledInput {...styledInputProps}>
      {children ? (
        <Typography variant="s3" color={colors.greyText[900]} sx={{ mb: 1 }}>
          {children}
        </Typography>
      ) : null}
      <TextField
        variant={variant}
        autoComplete
        {...props}
        InputProps={InputProps}
      />
    </StyledInput>
  );
  return inputVariant();
}

InputField.propTypes = {
  variant: PropTypes.string,
  children: PropTypes.string,
  error: PropTypes.bool,
};

InputField.defaultProps = {
  variant: "outlined",
  autoComplete: "off",
  error: false,
};
export default InputField;
