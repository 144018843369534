import * as admin_assets from "../../assets/Admin"
const AdminActiveFormContent = () => {
    return (
        <div className="col-md-6 col-lg-3 p-2 ">
            <div className="card mt-5 mt-md-5 mt-lg-3 position-relative shadow-sm">
                <img src={admin_assets.serviceImg} />
                <div className="btn-delete position-absolute">
                    <img src={admin_assets.deleteIcon} className="" />
                </div>
                <div className="card-body">
                    <a href="#" className="link-dark text-decoration-none"
                    >
                        <h3
                         style={{color: "#343434" , fontFamily:"cabin", fontWeight:500, fontSize: "14px"}}
                        className="card-title d-flex align-items-center justify-content-center justify-content-md-start gap-3">
                            <img
                                src={admin_assets.pdfIcon}
                                className="me-3"
                            />
                            Hair Treatment After care
                        </h3>
                    </a>
                </div>
            </div>
        </div>
    )
}
export default AdminActiveFormContent;