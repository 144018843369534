import React from "react";
import styled from "styled-components";

const PageContainer = styled.div`
  font-family: "Cabin", sans-serif;
  height: 100vh;
  background-color: #fff9f1;
  display: flex;
  flex-direction: column;
`;

const TopBar = styled.div`
  height: 80px;
  background: #ffffff;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Logo = styled.div`
  font-weight: bold;
  font-size: 24px;
  margin-left: 30px;
`;

const RightSide = styled.div`
  display: flex;
  align-items: center;
  margin-right: 30px;
`;

const DateAndTime = styled.div`
  font-size: 14px;
  color: #4c4d4f;
  margin-right: 30px;
`;

const NotificationContainer = styled.div`
  width: 48px;
  height: 48px;
  background: #f8f8f8;
  border-radius: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 20px;
`;

const MainLayout = styled.div`
  display: flex;
  flex: 1;
  background: #fff9f1;
`;

const Sidebar = styled.div`
  background: #ffffff;
  min-width: 240px;
  min-height: 100vh;
  padding-top: 30px;
  padding-left: 10px;
  padding-right: 20px;
`;

const Navigation = styled.ul`
  padding: 0;
`;

const ListItem = styled.li`
  list-style: none;
  padding: 14px 0 14px 20px;
  border-radius: 14px;
`;

const NavLink = styled.a`
  text-decoration: none;
  color: black;

  &:hover {
    color: black;
  }
`;

const ActiveLink = styled(NavLink)`
  font-weight: bold;
`;

const AccordionButton = styled.button`
  padding: 0;

  &.collapsed {
    box-shadow: none;
  }
`;

const Sublink = styled.div`
  margin-top: 15px;
`;

const SublinkText = styled.a`
  font-weight: 400;
  font-size: 14px;
  color: #4c4d4f;
  padding-left: 12px;
`;

const MainSection = styled.div`
  margin-left: 30px;
  margin-top: 20px;
  padding-right: 20px;
`;

const AppointmentHeading = styled.div`
  font-size: 30px;
  color: #4c4d4f;
  margin-bottom: 10px;
`;

const AppointmentText = styled.div`
  font-size: 20px;
  line-height: 22px;
  color: rgba(76, 77, 79, 0.85);
`;

const AppointmentButton = styled.div`
  margin-top: 15px;
  background: rgba(242, 236, 217, 0.6);
  border-radius: 5px;
  height: 42px;
  font-size: 18px;
  color: rgba(0, 0, 0, 0.5);
  padding: 0 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  width: 100%;
`;

const MapSection = styled.div`
  margin-top: 20px;
`;

const AppointmentsPage = () => {
  return (
    <PageContainer>
      <TopBar>
        <Logo>Logo</Logo>
        <RightSide>
          <DateAndTime>Sunday, 20 August</DateAndTime>
          <DateAndTime>1:50 PM</DateAndTime>
          <NotificationContainer>
            <svg width="20" height="22" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M11.73 20C11.5542 20.3031 11.3019 20.5547 10.9982 20.7295C10.6946 20.9044 10.3504 20.9965 10 20.9965C9.64964 20.9965 9.30541 20.9044 9.00179 20.7295C8.69818 20.5547 8.44583 20.3031 8.27002 20M16 7C16 5.4087 15.3679 3.88258 14.2426 2.75736C13.1174 1.63214 11.5913 1 10 1C8.4087 1 6.88258 1.63214 5.75736 2.75736C4.63214 3.88258 4 5.4087 4 7C4 14 1 16 1 16H19C19 16 16 14 16 7Z"
                stroke="#4C4D4F"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </NotificationContainer>
        </RightSide>
      </TopBar>
      <MainLayout>
        <Sidebar>
          <Navigation>
            <ListItem>
              <ActiveLink href="#">Overview</ActiveLink>
            </ListItem>
            <ListItem>
              <NavLink href="#">Profile</NavLink>
            </ListItem>
            <ListItem>
              <AccordionButton
                className="accordion-button collapsed"
                data-bs-toggle="collapse"
                data-bs-target="#historyCollapse"
                aria-expanded="false"
                aria-controls="historyCollapse"
              >
                History
              </AccordionButton>
              <div id="historyCollapse" className="collapse">
                <Sublink>
                  <SublinkText href="#">Videos</SublinkText>
                </Sublink>
                <Sublink>
                  <SublinkText href="#">Videos Library</SublinkText>
                </Sublink>
              </div>
            </ListItem>
            <ListItem>
              <NavLink href="#">Messages</NavLink>
            </ListItem>
            <ListItem>
              <AccordionButton
                className="accordion-button collapsed"
                data-bs-toggle="collapse"
                data-bs-target="#supportCollapse"
                aria-expanded="false"
                aria-controls="supportCollapse"
              >
                Support
              </AccordionButton>
              <div id="supportCollapse" className="collapse">
                <Sublink>
                  <SublinkText href="#">FAQ's</SublinkText>
                </Sublink>
                <Sublink>
                  <SublinkText href="#">Procedure Information</SublinkText>
                </Sublink>
              </div>
            </ListItem>
            <ListItem>
              <AccordionButton
                className="accordion-button collapsed"
                data-bs-toggle="collapse"
                data-bs-target="#aftercareCollapse"
                aria-expanded="false"
                aria-controls="aftercareCollapse"
              >
                Aftercare
              </AccordionButton>
              <div id="aftercareCollapse" className="collapse">
                <Sublink>
                  <SublinkText href="#">Quizzes</SublinkText>
                </Sublink>
                <Sublink>
                  <SublinkText href="#">Health & Skin Assessment</SublinkText>
                </Sublink>
              </div>
            </ListItem>
            <ListItem>
              <NavLink href="#">Finances</NavLink>
            </ListItem>
            <ListItem>
              <NavLink href="#">Virtual Consultation</NavLink>
            </ListItem>
          </Navigation>
        </Sidebar>
        <div className="col-xl-4">
          <MainSection>
            <AppointmentHeading>Appointments</AppointmentHeading>
            <AppointmentText>Choose a studio</AppointmentText>
            <AppointmentButton>
              <div>Toorak</div>
              <div>
                <svg width="7" height="12" xmlns="http://www.w3.org/2000/svg">
                  <line
                    x1="0.353553"
                    y1="0.646447"
                    x2="6.35355"
                    y2="6.64645"
                    stroke="black"
                  />
                  <line
                    x1="6.32009"
                    y1="6.38411"
                    x2="0.320092"
                    y2="11.3841"
                    stroke="black"
                  />
                </svg>
              </div>
            </AppointmentButton>
            <AppointmentButton>
              <div>Prahran</div>
              <div>
                <svg width="7" height="12" xmlns="http://www.w3.org/2000/svg">
                  <line
                    x1="0.353553"
                    y1="0.646447"
                    x2="6.35355"
                    y2="6.64645"
                    stroke="black"
                  />
                  <line
                    x1="6.32009"
                    y1="6.38411"
                    x2="0.320092"
                    y2="11.3841"
                    stroke="black"
                  />
                </svg>
              </div>
            </AppointmentButton>
            <AppointmentButton>
              <div>Brighton</div>
              <div>
                <svg width="7" height="12" xmlns="http://www.w3.org/2000/svg">
                  <line
                    x1="0.353553"
                    y1="0.646447"
                    x2="6.35355"
                    y2="6.64645"
                    stroke="black"
                  />
                  <line
                    x1="6.32009"
                    y1="6.38411"
                    x2="0.320092"
                    y2="11.3841"
                    stroke="black"
                  />
                </svg>
              </div>
            </AppointmentButton>
          </MainSection>
        </div>
        <div className="col-xl-6">
          <MapSection>
            {/* <iframe
              src="https://www.google.com/maps/embed?pb=!1m16!1m12!1m3!1d2965.0824050173574!2d-93.63905729999999!3d41.998507000000004!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!2m1!1sWebFilings%2C+University+Boulevard%2C+Ames%2C+IA!5e0!3m2!1sen!2sus!4v1390839289319"
              width="100%"
              height="600"
              frameBorder="0"
              style="border:0"
            ></iframe> */}
          </MapSection>
        </div>
      </MainLayout>
    </PageContainer>
  );
};

export default AppointmentsPage;
