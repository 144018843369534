import React from "react";
import { styled } from "styled-components";
import Logos from "../../assets/logos.svg";

const HeaderWrapper = styled.div`
  background-color: #fff;
  height: 8rem;
  text-align: center;

  display: flex;
  justify-content: center;
  align-items: center;
  .h1 {
    font-size: 3rem;
  }
  .h6 {
    font-size: 2.4rem;
  }
`;

const Header = () => {
  return (
    <HeaderWrapper>
      {/* <h1 className="h1">SKINCLUB</h1>
      <h6 className="h6">COSMETIC DOCTORS</h6> */}
      <img src={Logos} />
    </HeaderWrapper>
  );
};

export default Header;
