import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Home from "../pages/Home";
import NotFound from "../component/NotFund/NotFound";
import Signin from "../component/Signin/Signin";
import Signup from "../pages/Signup";
import Skin from "../pages/Skin";
import Login from "../pages/Login";
import ForgetPassword from "../pages/ForgetPassword";
import ResetPassword from "../pages/ResetPassword";
import AdminActiveCareFroms from "../pages/Admin/AdminActiveCareFroms";
import AdminTreatments from "../pages/Admin/AdminTreatments";
import AdminProductsPage from "../pages/Admin/AdminProducts";
import AdminProfileManagementPage from "../pages/Admin/AdminProfileManagmentPage";



const Routers = () => {
  return (
    <>
      <Routes>
        <Route exact path="/" element={<Navigate to="/login" />} />
        {/* <Route exact path="/home" element={<Home />} /> */}
        {/* <Route exact path="/signin" element={<Signin />} /> */}
        <Route exact path="/signup" element={<Signup />} />
        <Route exact path="/skin" element={<Skin />} />
        <Route exact path="/login" element={<Login />} />
        <Route exact path="/forgetpassword" element={<ForgetPassword />} />
        <Route exact path="/resetpassword" element={<ResetPassword />} />
        <Route path="*" element={<NotFound />} />
        <Route path="/admin/ActiveCareFroms" element={<AdminActiveCareFroms />} />
        <Route path="/admin/AdminTreatments" element={<AdminTreatments />} />
        <Route path="/admin/AdminProductsPage" element={<AdminProductsPage />} />
        <Route path="/admin/AdminProfileManagementPage" element={<AdminProfileManagementPage />} />

    

      </Routes>
    </>
  );
};

export default Routers;
