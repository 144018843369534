import React from "react";
import styled from "@emotion/styled";
// import InputField from "../component/InputField/InputField";
import Button from "../component/Button/Button";
import InputField from "../component/InputField/InputField";

const StyledInput = styled("div")({
  display: "flex",
  // flexDirection: "column",
  // alignItems: "flex-start",
  // width: "100%",
  justifyContent: "center",
  alignItems: "center",
  height: "100vh",
  width: "100vw",
  // backgroundColor: "yellow",
});

const Home = () => {
  return (
    <StyledInput>
      <InputField
        fullWidth
        placeholder=""
        // label="fullWidth"
        variant="outlined"
        style={{ margin: "auto" }}
      />
      {/* <Button variant="outlinedvariant" style={{ margin: "auto" }}>
        testing
      </Button> */}
    </StyledInput>
  );
};

export default Home;
