import React from "react";
import Styled from "styled-components";

const InputWrapper = Styled.div`
// background-color:green;
.input{
  all: unset;
border-bottom:1px solid #4C4D4F;
font-size:1.6rem;
font-weight:400;
line-height:1.5;
padding:.8rem;
padding-left:0;
width:100%;
}

.h6{
  font-size:1.6rem;
font-weight:400;
line-height:1.5;
color:#4C4D4F;
margin-bottom:.8rem
}

`;

const Input = ({ name, placeholder, password = false, ...props }) => {
  return (
    <InputWrapper {...props}>
      <h6 className="h6">{name}</h6>
      <input
        className="input"
        type={password ? "password" : "text"}
        placeholder={placeholder}
      />
    </InputWrapper>
  );
};

export default Input;
