import React from "react";
import Input from "../Input/Input";
import Styled from "styled-components";
import ButtonComponent from "../ButtonComponent/ButtonComponent";
import Fb from "../../assets/fb.svg";
import Google from "../../assets/google.svg";
import Twitter from "../../assets/twitter.svg";

const SignupWrapper = Styled.div`
// background-color:green;
padding:2.8rem 6.4rem 2.8rem 2.4rem;
height:100%;
background:#fff;
border-top-left-radius: 14px;
border-bottom-left-radius: 14px;


.account{
font-size: 3.4rem;
font-weight: 400;
line-height: 1.2;
color:#4C4D4F;
margin-bottom:1.4rem;

}
@media only screen and (max-width: 600px) {
  padding:2.8rem;
  .firstname{grid-column: 1 / span 2;}
  .lastname{grid-column: 1 / span 2;}
}
@media only screen and (max-width: 900px) {
  border-radius: 14px;
}

.paragraph{
  font-size: 1.4rem;
  font-weight: 400; 
  line-height: 1.5;
  }
  
  .already-exist{
    font-size: 1.4rem;
  font-weight: 400; 
  line-height: 1.5;
  color:#aaabac;
  margin-bottom:3.9rem;
  }
  
  .span-login{
    color: #4C4D4F;
    text-decoration: underline;
    color:#4C4D4F;
  }

  .email{
    grid-column: 1 / span 2;
  }

  .password{
    grid-column: 1 / span 2;
  }
  .already{
    grid-column: 1 / span 2;
    font-size: 1.2rem;
    font-weight: 400; 
    line-height: 1.5;
    text-align:center;

    span{
      text-decoration: underline;
      color:#4C4D4F;
    }
  }
  .btn{
    grid-column: 1 / span 2;
    margin-bottom:.8rem;
  }
  .policy-text{
    grid-column: 1 / span 2;
    margin-bottom:1.4rem;

    font-size: 1.2rem;
    font-weight: 400; 
    line-height: 1.5;

    span{
      text-decoration: underline;
      color:#4C4D4F;
    }
  }
  .checkk{
    grid-column: 1 / span 2;
    display: flex;
    align-items: center;
    gap: 0.8rem;
    margin-bottom:2.5rem;
    // margin: -10px;
  label{
    color:#4C4D4F;
    font-size:1.2rem;
  }
  }
`;

const SignupForm = Styled.form`
// background:yellow;

display: grid;
grid-gap: 32px;
grid-template-columns: repeat(2,1fr);
margin-bottom:2.4rem;
`;

const AuthButton = Styled.button`
all: unset;
width:100%;
box-sizing: border-box;
color: #4C4D4F;
border-radius: 24px;
border: 1px solid #4C4D4F;
padding:1.4rem;
display: flex;
justify-content: center;
align-items: center;
gap:1.2rem;

    margin-bottom:1rem;

`;

const WrapperOR = Styled.div`
position :relative;
color:#4C4D4F;
margin-top:3rem;
margin-bottom:4.8rem;
p{
  position:absolute;
  top:50%;
  left:50%;
  transform:translate(-50%,-50%);
  font-size:1.4rem;
  font-weight:400;
  color:#4C4D4F;
  background:#fff;
  padding:1.7rem;
}
`;

const Forget = () => {
  return (
    <SignupWrapper>
      <h1 className="account">Forgot your password?</h1>
      <p className="already-exist">
        No worries, enter your email below to get instructions to reset your
        password
        {/* <span className="span-login"> Sign up</span> */}
      </p>

      <SignupForm>
        <Input name="Email" placeholder="Jane@gmail.com" className="email" />
      </SignupForm>
      <div>
        <ButtonComponent className="btn">Reset Password</ButtonComponent>
        <p className="already">I remember my password</p>
      </div>
    </SignupWrapper>
  );
};

export default Forget;
