import React from "react";
import Styled from "styled-components";

const ButtonWrapper = Styled.button`
  all: unset;
font-size:1.6rem;
font-weight:400;
line-height:1.5;
padding:.8rem;
padding-left:0;
width:100%;
background:#000;
color: #FFFFFF;
border-radius: 24px;
text-align: center;
cursor:pointer;

`;

const ButtonComponent = ({ children, ...props }) => {
  return <ButtonWrapper {...props}>{children}</ButtonWrapper>;
};

export default ButtonComponent;
