import * as admin_assets from "../../assets/Admin";
import "../../pages/Admin/style.css";

const AdminProducts = () => {
    const isMobile = window.innerWidth <= 992;
  return (
    <div  className="col-lg-4 col-md-3 col-12">
      <div
        style={{ borderRadius: "10px" }}
        className="bg-white border border-gray d-flex flex-column flex-xxl-row p-3 gap-4"
      >
        <div style={{ width: isMobile? "100%" : "35%"  }}>
          <img
            src={admin_assets.product}
            className="w-100"
            alt=""
          />
        </div>
        <div style={{ width: isMobile? "100%" : "45%" }}>
          <h5 style={{fontSize:"16px"}} className="text-black mt-3">Vitamin A Night Serum</h5>
          <h6 style={{fontSize:"10px", color: "#4C4D4F", marginBottom: "0" }}>30 ML / 1 FL OZ</h6>
          <p style={{ fontSize:"10px", color: "#4C4D4F", opacity: "50%", marginBottom: "0" }}>Brightening Vitamin A Serum</p>
          <div className="d-flex gap-2 align-items-center">
            <div className="d-flex align-items-center">
              <img className="align-middle" src={admin_assets.star} alt="" />
              <img className="align-middle" src={admin_assets.star} alt="" />
              <img className="align-middle" src={admin_assets.star} alt="" />
              <img className="align-middle" src={admin_assets.star} alt="" />
              <img className="align-middle" src={admin_assets.star} alt="" />
            </div>
            <h1 className="pt-1 mt-1" style={{ fontSize: "10px", fontFamily: "cabin" }}>
              30 reviews
            </h1>
          </div>
          <div  style={{fontSize:"10px"}} className="price-area ">
            <span className="me-2 text-black">$69.00</span>
            <span className="text-decoration-line-through">
              <strong>$90.00</strong>
            </span>
          </div>
          <button style={{marginTop: isMobile? "30px" : "60px",fontSize: "14px"}} type="button" className="w-100 rounded-pill btn-admin btn-admin-primary">
            Remove
          </button>
        </div>
      </div>
    </div>
  );
};

export default AdminProducts;